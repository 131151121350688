<template>
  <div v-if="overlays" class="wrapper">
    <div>
      <div
        class="image"
        :style="`transform: translate(${currentOverlay.carousel.x}px, ${currentOverlay.carousel.y}px); height: ${currentOverlay.carousel.height}px; width: ${currentOverlay.carousel.width}px; justify-items: ${currentOverlay.align.h}; align-items: ${currentOverlay.align.v}; justify-content: ${currentOverlay.align.h}; align-content: ${currentOverlay.align.v}; justify-self: ${currentOverlay.align.h}; align-self: ${currentOverlay.align.v} `"
      >
        <transition name="fade">
          <img
            v-if="showOverlay && currentOverlay.carousel.imageArr[0].type === 'image'"
            class="brandImage"
            :src="currentOverlay.carousel.imageArr[0].imageSrc"
          />
          <video
            v-else-if="showOverlay && currentOverlay.carousel.imageArr[0].type === 'video'"
            autoplay
            loop
            class="brandImage"
          >
            <source :src="currentOverlay.carousel.imageArr[0].imageSrc" />
          </video>
        </transition>
      </div>
    </div>
  </div>

  <!-- <p v-else>Not part of any active campaigns.</p> -->
</template>

<script>
import StreamService from "@/services/StreamService";

export default {
  name: "SingleUrlOverlay",

  data() {
    return {
      campaignId: null, // id URL query
      platform: null, // platform URL query
      handle: null, // handle URL query
      overlayStreamId: null, // overlay_tracker object id

      adCampaign: null,
      overlay: null,

      events: [], // OBS events
      brands_found: {}, // Overlay brands

      liveCheckInterval: undefined, // Fallback incase the streamer isn't using OBS
      overlayInterval: undefined, // window.setInterval for next time overlay is shown
      overlayDurationTimeout: undefined, // window.setTimeout for when image should be hidden
      backendPing: undefined, // window.setInterval for pinging backend with overlay_tracker data

      displayFreq: 5, // How often the ad is shown in minutes. Starter value = time to first ad display

      streamLive: false,
      showOverlay: true, // True so the streamer can see the overlay before going live

      campaignStatus: null,

      campaigns: null,
      overlays: null,

      activeOverlayIndex: 0,
    };
  },

  computed: {
    /**
     * @returns {object}
     */
    currentOverlay() {
      return this.overlays[this.activeOverlayIndex];
    },

    /**
     * @summary Generate display frequency based on based on chosen campaigns, so we never show the campaign more times than the display frequency allows.
     * @return {number} Return the highest display frequency / amount of campaigns, with a minimum value of 5
     * @todo Implement setting for content creator to set their minimum display frequency.
     * @example
     * We have 3 campaigns with a display frequency of 30, 45 and 60 ( [30, 45, 60] )
     * The highest value is 60, which we then divide by the amount of campaigns (3) (60 / 3 = 20)
     * We then check if the value we get is higher than our minimum display frequency (5).
     * If the calculated value is higher than our minimum, then we return that, otherwise we return our minimum value.
     */
    generateDisplayFreq() {
      if (!this.campaigns || !this.campaigns.length) return this.displayFreq;

      const displayFreqs = this.campaigns.map((c) => c.displayFreq);

      const maxVideoDuration = Math.max(
        ...this.campaigns?.map((c) => ((c?.tools?.overlay?.carousel?.duration ?? 1) + 5000) / 60000),
        1,
      );

      return Math.max(Math.max(...displayFreqs) / displayFreqs.length, maxVideoDuration);
    },
  },

  watch: {
    /**
     * @summary Makes it possible to have a 5 minute delay start
     */
    displayFreq() {
      console.log("watch displayFreq", new Date(), Date.now());
      this.startOverlayInterval();
    },
  },

  async created() {
    console.log("created", new Date(), Date.now());

    await this.getCampaign();

    this.createBrandData();

    if (["hougesen", "mvnne", "caveaio", "netwrkgg", "adlabgg"].includes(this.handle.toLowerCase())) {
      this.sendChatMessage();
    }

    /** Show the overlay for 30 seconds and then hide it. Done so the streamer can see the overlay position */
    window.setTimeout(() => {
      this.showOverlay = false;
    }, this.currentOverlay?.carousel?.duration ?? 30000);

    this.campaignStatus = {
      started: true,
      viewsAchieved: false,
    };

    if (this.campaignStatus?.started && !this.campaignStatus?.viewsAchieved) {
      console.log("Campaign is live && views has not been achieved yet");

      /** Check if the streamer is live using software other than OBS */
      this.startLiveCheckInterval();

      /** Runs when the stream is started. */
      window.addEventListener("obsStreamingStarted", (event) => {
        console.log("obsStreamingStarted", event, new Date(), Date.now());
        this.showOverlay = false;

        // Stop checking if the streamer is live using other software
        this.stopLiveCheckInterval();

        if (!this.streamLive) this.newStream();

        this.streamLive = true;

        this.startOverlayInterval();

        this.startBackendPing();
      });

      /** Runs when the streaming is stopping */
      window.addEventListener("obsStreamingStopping", (event) => {
        console.log("obsStreamingStopping", event, new Date(), Date.now());

        this.streamLive = false;

        this.endStream();
      });

      /** Fallback incase the streamer closes OBS before stopping the stream */
      window.addEventListener("obsExit", (event) => {
        console.log("obsExit", event, new Date(), Date.now());

        this.streamLive = false;

        this.endStream();
      });

      /** Runs everytime the source visibility changes */
      window.addEventListener("obsSourceVisibleChanged", (event) => {
        console.log("obsSourceVisibleChanged", event, new Date(), Date.now());

        if (event?.detail?.visible) {
          /** Start the advertisement interval again */
          this.startOverlayInterval();

          this.events.push({
            eventTitle: "visTrue",
            visible: true,
            time: new Date(),
          });
        } else {
          // Stop the advertisement interval so we don't show get incorrect data
          this.stopOverlayInterval();

          this.events.push({
            eventTitle: "visFalse",
            visible: false,
            time: new Date(),
          });
        }
      });
    }
  },

  methods: {
    /**
     * @summary Fetch & set campaign data
     */
    async getCampaign() {
      console.log("getCampaign", new Date(), Date.now());

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.campaignId = urlParams.get("id");

      const campaignData = await StreamService.getSingleUrlAdCampaign(this.campaignId);

      this.campaigns = campaignData.campaigns;

      this.overlays = campaignData.campaigns.map((c) => c.tools.overlay);

      this.handle = this.campaigns[0].handles[0].handle;
      this.platform = this.campaigns[0].handles[0].platform;
    },

    /**
     * @summary Request backend to generate a clip
     * @param {string} handle
     * @param {string} campaignId
     */
    async generateClip(handle, campaignId) {
      setTimeout(() => {
        StreamService.generateClip(campaignId, handle);
      }, 30000);
    },

    /**
     * @summary Check if the streamer is live using software other than OBS every 60 seconds
     */
    async startLiveCheckInterval() {
      console.log("startLiveCheckInterval", new Date(), Date.now());
      this.stopLiveCheckInterval();

      this.liveCheckInterval = window.setInterval(async () => {
        const result = await StreamService.checkIfLive(this.handle);

        console.log(result);

        if (result?.live) {
          this.showOverlay = false;

          this.streamLive = true;

          this.newStream();

          this.startOverlayInterval();

          this.startBackendPing();

          this.stopLiveCheckInterval();
        }
      }, 60000);
    },

    /**
     * @summary Make sure we don't have multiple startLiveCheckInterval() running
     */
    stopLiveCheckInterval() {
      console.log("stopLiveCheckInterval", new Date(), Date.now());
      clearInterval(this.liveCheckInterval);
      this.liveCheckInterval = undefined;
    },

    /**
     * @summary Starts the countdown to next time advertisement is shown
     */
    startOverlayInterval() {
      console.log("startOverlayInterval", new Date(), Date.now());
      this.stopOverlayInterval();

      this.overlayInterval = window.setInterval(() => {
        const currentTime = new Date().getHours();

        // Check whether the clock is between 06:00 and 02:00
        if (currentTime < 2 || currentTime >= 6) {
          console.log("overlayInterval", "SHOW OVERLAY", new Date(), Date.now());

          this.startOverlayDurationTimeout();

          this.displayFreq = this.generateDisplayFreq;
        }
      }, this.displayFreq * 60000);
    },

    /**
     * @summary Makes sure that we don't have multiple overlay intervals
     */
    stopOverlayInterval() {
      console.log("stopOverlayInterval", new Date(), Date.now());
      clearInterval(this.overlayInterval);
      this.overlayInterval = undefined;
    },

    /**
     * @summary Starts the duration that the media is shown
     */
    startOverlayDurationTimeout() {
      console.log("startOverlayDurationTimeout", new Date(), Date.now());

      this.stopOverlayDurationTimeout();

      if (this.streamLive) {
        // Create clip
        this.generateClip(this.handle, this.campaigns[this.activeOverlayIndex]._id);

        // Send chat message
        this.sendChatMessage();

        // Update AdCampaign stats
        this.updateCampaignStats();

        // Update post.ai found obj
        this.createFoundObj();
      }

      this.showOverlay = true;

      this.overlayDurationTimeout = window.setTimeout(() => {
        console.log("overlayDurationTimeout", new Date(), Date.now());

        this.showOverlay = false;
        this.activeOverlayIndex++;

        if (this.activeOverlayIndex > this.overlays.length - 1) {
          this.activeOverlayIndex = 0;
        }

        console.log(this.activeOverlayIndex, this.overlays.length);
      }, this.currentOverlay.carousel.duration);
    },

    /**
     * @summary Makes sure that we don't have multiple timeouts
     */
    stopOverlayDurationTimeout() {
      console.log("stopOverlayDurationTimeout", new Date(), Date.now());
      clearInterval(this.overlayDurationTimeout);
      this.overlayDurationTimeout = undefined;
    },

    /**
     * @summary Sends request to send chat message if adCampaign.tools.chatbot is defined
     */
    async sendChatMessage() {
      console.log("sendChatMessage", new Date(), Date.now());
      if (Object.keys(this.campaigns[this.activeOverlayIndex].tools.chatbot).length > 0) {
        const chatMsgRes = await StreamService.sendChatMessage(
          this.campaigns[this.activeOverlayIndex]._id,
          this.platform,
          this.handle,
        );
        console.log("chatMsgRes", chatMsgRes, new Date(), Date.now());
      }
    },

    /**
     * @summary Sends request to update campaign stats
     */
    async updateCampaignStats() {
      console.log("updateCampaignStats", new Date(), Date.now());
      const updateCampaignStatsRes = await StreamService.updateCampaignStats(
        this.campaigns[this.activeOverlayIndex]._id,
        this.campaignId,
      );

      if (updateCampaignStatsRes && updateCampaignStatsRes.status) {
        this.campaignStatus = updateCampaignStatsRes.status;
      }

      // Stop overlay if view target has been achived
      if (updateCampaignStatsRes?.status?.viewsAchieved) {
        this.endStream();
      }

      console.log("updateCampaignStatsRes", updateCampaignStatsRes, new Date(), Date.now());
    },

    /**
     * @summary Insert a new document in overlay_trackers. Runs when OBS sends an "obsStart"
     */
    async newStream() {
      console.log("newStream", new Date(), Date.now());
      this.events.push({
        eventTitle: "obsStart",
        visible: true,
        time: new Date(),
      });

      const request = await StreamService.newOverlayStream(
        this.campaignId,
        this.platform,
        this.handle,
        this.events,
        this.createCarouselObjForDB(),
        this.brands_found, // found?
      );

      console.log("request.overlayStreamId", request.overlayStreamId, new Date(), Date.now());

      this.overlayStreamId = request.overlayStreamId;

      console.log("this.overlayStreamId", this.overlayStreamId, new Date(), Date.now());
    },

    /**
     * @summary Updates the overlay_trackers document
     */
    async updateOverlayStream() {
      await StreamService.updateOverlayStream(this.overlayStreamId, this.events, this.brands_found);
      console.log("updateOverlayStream", new Date(), Date.now());
    },

    /**
     * @summary Sends request to stop tracking this overlay stream. Runs when OBS sends an "obsStreamingStopping" OR "obsExit" event to us.
     */
    async endStream() {
      console.log("endStream", new Date(), Date.now());

      // Makes sure overlay is stopped
      this.stopOverlayInterval();

      // Makes sure image is stopped
      this.stopOverlayDurationTimeout();

      // Stops the overlay from pinging the api further
      this.stopBackendPing();

      this.events.push({
        eventTitle: "obsStop",
        visible: false,
        time: new Date(),
      });

      const request = await StreamService.endOverlayStream(this.overlayStreamId, this.events, this.brands_found);
      console.log("StreamService.endOverlayStream request ", request, new Date(), Date.now());
    },

    /**
     * @summary Creates a carousel object for overlay_tracker db document
     * @returns {object[]} carousels
     */
    createCarouselObjForDB() {
      console.log("createCarouselObjForDB", new Date(), Date.now());
      const carousels = [];

      for (const overlay of this.overlays) {
        const newCarousel = {
          duration: overlay.carousel.imageArr[0].duration,
          brands: [],
        };

        const brandObj = {
          brandId: overlay.carousel.imageArr[0].brandId,
          brandImage: overlay.carousel.imageArr[0].imageSrc,
        };

        newCarousel.brands.push(brandObj);

        carousels.push(newCarousel);
      }

      console.log("createCarouselObjForDB carousels", carousels);
      return carousels;
    },

    /**
     * @summary Creates brand data obj where we can push found objects into
     */
    createBrandData() {
      console.log("createBrandData", new Date(), Date.now());

      for (const overlay of this.overlays) {
        console.log(overlay);
        this.brands_found[overlay.carousel.imageArr[0].brandId] = {
          found: [],
        };
      }

      console.log("createBrandData this.brands_found", this.brands_found);
    },

    /**
     * @summary Creates a new found object. Used for calculation brand_value in the backend
     */
    createFoundObj() {
      console.log("createFoundObj", new Date(), Date.now());

      // Get time from stream start
      const getFrom = Math.floor((new Date().getTime() - new Date(this.events[0].time).getTime()) / 1000);

      // Time from start start + image duration
      const getTo = getFrom + this.overlays[this.activeOverlayIndex].carousel.imageArr[0].duration / 1000;

      const foundObj = {
        logo_name: this.overlays[this.activeOverlayIndex].carousel.imageArr[0].title.toLowerCase() || "",
        brand_id: this.overlays[this.activeOverlayIndex].carousel.imageArr[0].brandId,
        h: this.overlays[this.activeOverlayIndex].carousel.height,
        w: this.overlays[this.activeOverlayIndex].carousel.width,
        x: this.overlays[this.activeOverlayIndex].carousel.x,
        y: this.overlays[this.activeOverlayIndex].carousel.y,
        from: getFrom,
        to: getTo,
        duration: this.overlays[this.activeOverlayIndex].carousel.imageArr[0].duration / 1000,
      };

      this.brands_found[this.overlays[this.activeOverlayIndex].carousel.imageArr[0].brandId].found.push(foundObj);

      console.log("foundObj", foundObj, new Date(), Date.now());
    },

    /**
     * @summary Function that every 10 seconds runs the updateOverlayStream function.
     */
    startBackendPing() {
      console.log("startBackendPing", new Date(), Date.now());

      // Makes sure we can't start 2 intervals at the same time.
      this.stopBackendPing();

      // The interval is set a data variable (this.backendPing), so that we can clearInterval from another function
      this.backendPing = window.setInterval(() => {
        console.log("update backend", new Date(), Date.now());
        this.updateOverlayStream();
      }, 10000);
    },

    /**
     * @summary Stops the backend pinging
     */
    stopBackendPing() {
      console.log("stopBackendPing", new Date(), Date.now());

      clearInterval(this.backendPing);
      this.backendPing = undefined;
    },
  },
};
</script>

<style lang="scss">
.brandImage {
  max-height: 100%;
  max-width: 100%;
}
</style>
