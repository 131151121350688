<template>
  <div>
    <SingleUrlOverlay />
  </div>
</template>

<script>
import SingleUrlOverlay from "@/components/SingleUrlOverlay";

export default {
  name: "App",

  components: {
    SingleUrlOverlay,
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1.6;
}
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1.6;
}

html {
  scroll-behavior: smooth;
}

body,
html {
  height: 100%;
  padding-top: 0;
  width: 100%;
}
</style>
