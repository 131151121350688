import axios from "axios";

const NETWRK_API_URL = process.env.VUE_APP_NETWRK_API_URL;
const CHATBOT_URL = process.env.VUE_APP_CHATBOT_URL;

export default class StreamService {
  /**
   * @deprecated
   * @summary Get overlay from params
   * @param {string} id
   * @param {string} platform
   * @param {string} handle
   * @returns {Promise<object>} - Ad campaign object
   */
  static async getAdCampaign(id, platform, handle) {
    const URL = `${NETWRK_API_URL}/ad-campaign/overlay/${id}/${platform}/${handle}`;

    return await axios
      .get(URL)
      .then((response) => response.data)
      .catch((err) => {
        console.log("getAdCampaign ERROR", err.response.data);
        return err.response.data;
      });
  }

  /**
   *
   * @param {string} AdCampaign._id
   * @returns {Promise<object[]>}
   */
  static async getSingleUrlAdCampaign(id) {
    const URL = `${NETWRK_API_URL}/overlay/multi/${id}`;

    return await axios
      .get(URL)
      .then((response) => response.data)
      .catch((err) => {
        console.log("getAdCampaign ERROR", err.response.data);
        return err.response.data;
      });
  }

  /**
   * @summary Create new overlay_tracker
   * @param {string} overlayId
   * @param {string} platform
   * @param {string} handle
   * @param {object[]} events
   * @param {object} carousels
   * @param {array} stats
   * @returns {Promise<{success: boolean, msg: string, overlayStreamId: string | null, t: any | null, err: any | null }>}
   */
  static async newOverlayStream(overlayId, platform, handle, events, carousels, stats) {
    const URL = `${NETWRK_API_URL}/newOverlayStream`;

    return await axios
      .post(URL, {
        overlayId,
        platform,
        handle,
        events,
        carousels,
        stats,
      })
      .then((response) => response.data)
      .catch((err) => {
        console.log("newOverlayStream ERROR", err.response.data);
        return err.response.data;
      });
  }

  /**
   * @summary Update overlay_tracker stats
   * @param {string} overlayStreamId
   * @param {array} events
   * @param {array} stats
   * @returns {Promise<{ success: boolean, msg: string, err:any }>}
   */
  static async updateOverlayStream(overlayStreamId, events, stats) {
    const URL = `${NETWRK_API_URL}/updateOverlayStream`;

    return await axios
      .post(URL, {
        overlayStreamId,
        events,
        stats,
      })
      .then((response) => response.data)
      .catch((err) => {
        console.log("updateOverlayStream ERROR", err.response.data);
        return err.response.data;
      });
  }

  /**
   * @summary Send request to stop tracking overlay stream
   * @param {string} overlayStreamId
   * @param {array} events
   * @param {array} stats
   * @returns {Promise<{ success: boolean, msg: string, err: any | null  }>}
   */
  static async endOverlayStream(overlayStreamId, events, stats) {
    const URL = `${NETWRK_API_URL}/endOverlayStream`;

    return await axios
      .post(URL, {
        overlayStreamId,
        events,
        stats,
      })
      .then((response) => response.data)
      .catch((err) => {
        console.log(err.response.data);
        return err.response.data;
      });
  }

  /**
   * @summary Send a new chatbot message to platform/handle
   * @param {string} campaignId
   * @param {string} platform
   * @param {string} handle
   * @returns {Promise<void>}
   */
  static async sendChatMessage(campaignId, platform, handle) {
    const URL = `${CHATBOT_URL}/send-message/${campaignId}/${platform}/${handle}`;

    await axios
      .get(URL)
      .then((res) => res.data)
      .catch((err) => console.log("sendChatMessage ERROR", err));
  }

  /**
   * @summary Update AdCamapign stream stats
   * @param {string} campaignId
   * @param {string} platform
   * @param {string} handle
   * @returns {Promise<void>}
   */
  static async updateCampaignStats(campaignId, contentCreatorId) {
    const URL = `${NETWRK_API_URL}/ad-campaign/stats/${campaignId}/${contentCreatorId}`;

    await axios
      .put(URL)
      .then((res) => res.data)
      .catch((err) => console.log("updateCampaignStats ERROR", err));
  }

  /**
   * @summary Check if streamer is live on Twitch
   * @param {string} handle
   * @returns { Promise<{live: boolean }> }
   */
  static async checkIfLive(handle) {
    const URL = `${NETWRK_API_URL}/twitch-live-check/${handle}`;

    return await axios
      .get(URL)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err.response.data);
        return err.response.data;
      });
  }

  /**
   * @summary Generates clip from broadcast
   * @param {string} campaignId
   * @param {string} handle
   * @returns {Promise<void>}
   */
  static generateClip(campaignId, handle) {
    const URL = `${NETWRK_API_URL}/clip/${campaignId}/${handle}`;

    axios.post(URL).catch((err) => console.log("generateClip ERROR", err));
  }
}
